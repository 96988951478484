import MDBox from "components/MDBox";
import TLinkApi from "services/tlink.api";
import React, { useEffect, useState } from "react";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import CollapsibleTable from "./components/qualificationTable";

function Qualification() {
  const [qualification, setQualification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [languageId, setLanguageId] = useState("1");
  const { language } = GetLanguageList();
  const [search, setSearch] = useState("");
  const [check, setCheck] = useState(false);

  let getQualificationList = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/qualifications/${languageId}?query=${search}&all=${check}`);
      setQualification(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let searchQualificationList = async () => {
    if (languageId !== "") {
      if (search === "") {
        setIsLoading(true);
      }
      try {
        let data = await TLinkApi.get(`/qualifications/${languageId}?query=${search}`);
        setQualification(data);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    searchQualificationList();
    // eslint-disable-next-line
  }, [languageId]);

  useEffect(() => {
    getQualificationList();
    // eslint-disable-next-line
  }, [search, check]);

  return (
    <MDBox py={2} mt={3} px={2}>
      <CollapsibleTable
        qualification={qualification}
        isLoading={isLoading}
        language={language}
        setLanguageId={setLanguageId}
        languageId={languageId}
        setSearch={setSearch}
        setCheck={setCheck}
      />
    </MDBox>
  );
}

export default Qualification;
