import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import Axios from "axios";

const GetRolesList = () => {
  const [Roles, setRoles] = useState([]);
  const [RolesMap, setRolesMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GetRoles = async () => {
    try {
      const { data, status } = await Axios.get(TLinkApi.baseUrl + "/role-list");
      setRoles(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  console.log(Roles);
  useEffect(() => {
    GetRoles();
    // eslint-disable-next-line
  }, []);
  return {
    Roles,
    GetRoles,
    RolesMap,
    isLoading,
  };
};

export default GetRolesList;