import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetCampaignList = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");

  const getCampaignData = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/campaign?search=${search}&page=${page}&limit=${10}`);
      setCampaigns(data.data.data);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getCampaignData();
    // eslint-disable-next-line
  }, [page, search]);
  return {
    campaigns,
    getCampaignData,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
  };
};

export default GetCampaignList;
