import DataTable from "comps/Tables/DataTable";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import { useNavigate } from "react-router-dom";
import { Grid, Tooltip } from "@mui/material";
import SearchInput from "components/searchInput";
import { useState } from "react";
import GetTopSurveyList from "hooks/TopSurveyList/GetTopSurveyList";
import { useEffect } from "react";

function TopSurvey({ timeline }) {
  let navigate = useNavigate();
  const { topSurveys } = GetTopSurveyList(timeline);
  const [search, setSearch] = useState("");
  const [filterTopSurvey, setFilterTopSurvey] = useState([]);

  useEffect(() => {
    if (search && search !== "") {
      const fTopSurvey = topSurveys
        .filter((survey) => {
          if (search === "") return true;
          else {
            const s = search.toLowerCase();
            let match = false;
            if (survey.id && survey.id.toString().includes(s)) {
              match = true;
            }
            if (survey.surveyName && survey.surveyName.toLowerCase().includes(s)) {
              match = true;
            }
            return match;
          }
        })
        .map((c) => c);
      setFilterTopSurvey(fTopSurvey);
    } else {
      setFilterTopSurvey(topSurveys);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    setFilterTopSurvey(topSurveys);
    // eslint-disable-next-line
  }, [topSurveys]);

  let columns = [
    {
      Header: "Survey Id",
      accessor: "surveyId",
      align: "left",
      Cell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/surveys/${params.value}`);
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      Header: "Client Name",
      accessor: "clientName",
      align: "center",
      Cell: ({ row }) => {
        const name = row?.values?.clientName || "";
        const truncatedName = name.length > 8 ? name.substring(0, 8) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "projectId",
      align: "center",
      Cell: (params) => {
        const name = params.row.original.projectName || "";
        const trancutedName = name.length > 8 ? name.substring(0, 8) + "..." : name;

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/projects/${params.value}`);
            }}
          >
            <Tooltip title={name}>
              <div>{trancutedName}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Survey Name",
      accessor: "surveyName",
      width: "10%",
      align: "center",
      Cell: (params) => {
        const name = params?.value;
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip
            title={name}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/surveys/${params.cell.row.original.surveyId}`);
            }}
          >
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "buyerName",
      Header: "Buyer Name",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.values?.buyerName || "";
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    { Header: "Sample Required", accessor: "sampleRequired", align: "center" },

    { Header: "Starts", accessor: "starts", align: "center" },
    {
      Header: "Completes",
      accessor: "completes",
      align: "center",
    },
    {
      Header: "Conversion",
      accessor: "conversion",
      align: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.conversion).toFixed(2);
      },
    },
    {
      Header: "Country",
      accessor: "countryCode",
      align: "center",
    },
    {
      Header: "CPI",
      accessor: "cpi",
      align: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.cpi).toFixed(2);
      },
    },
    {
      Header: "EPC",
      accessor: "epc",
      align: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.epc).toFixed(2);
      },
    },
    {
      Header: "completion",
      accessor: "completion",
      align: "center",
      Cell: (params) => {
        const completionValue =
          params.cell.row.original.starts <= 0
            ? 0
            : Math.ceil(
                (params.cell.row.original.completes / params.cell.row.original.sampleRequired) * 100
              );

        return (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={completionValue} color="error" variant="gradient" label={true} />
          </MDBox>
        );
      },
    },
  ];

  const rows = filterTopSurvey && filterTopSurvey;

  return (
    <MDBox>
      <Grid container spacing={2} pr={3} pt={2} pl={3}>
        <Grid item xs={12} md={3} style={{ color: "#6E9633" }}></Grid>
        <Grid
          item
          xs={12}
          md={9}
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-end", md: "center" },
          }}
          gap="15px"
        >
          <SearchInput search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted
        noEndBorder
        tableMaxHeight="700px"
        entriesPerPage={false}
        canSearch={false}
      />
    </MDBox>
  );
}

export default TopSurvey;
