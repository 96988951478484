import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Icon,
  IconButton,
  Switch,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

function ClientBasicDetails() {
  const navigate = useNavigate();
  const { client, setClient, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      isActive: false,
      isApiClient: false,
      code: "",
      building: "",
      street: "",
      state: "",
      country: "",
      city: "",
      zipCode: "",
    },
  });

  let isActive = watch("isActive");
  let isApiClient = watch("isApiClient");

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      setValue("email", client.email);
      setValue("name", client.name);
      setValue("phone", client.phone);
      setValue("isActive", client.isActive);
      setValue("isApiClient", client.isApiClient);
      setValue("code", client.code);
      setValue("building", client.building);
      setValue("street", client.street);
      setValue("country", client.country);
      setValue("city", client.city);
      setValue("state", client.state);
      setValue("zipCode", client.zipCode);
    }
    // eslint-disable-next-line
  }, [client]);

  const { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  async function getData() {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/clients/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/clients/${id}`);
          setClient(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/clients/${client.id}`, data);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        setClient({});

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/clients/${result.data.id}/clientEncryption/edit`);
      } else {
        let result = await TLinkApi.post("/clients", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setClient({});

        navigate(`/clients/${result.data.id}/clientEncryption/edit`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
            minHeight: "700px",
          }}
          p={3}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/clients/${id}/edit`);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mb={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Client Details"}
            </MDTypography>
            <Grid container spacing={3} pt={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Client Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      label="Client Name"
                      autoComplete="off"
                      {...register("name", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.name ? true : false}
                      helperText={errors?.name ? errors.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="code"
                  control={control}
                  rules={{
                    required: "Client Code is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      label="Client Code"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.code ? true : false}
                      helperText={errors?.code ? errors.code.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Client Phone is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      label="Contact Number"
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.phone ? true : false}
                      helperText={errors?.phone ? errors.phone.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      label="Email Address"
                      required
                      {...register("email", {
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors.email.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" flexWrap="wrap">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isEdit.disabled}
                      checked={isApiClient}
                      onChange={(e, value) => {
                        setValue("isApiClient", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is API Client&nbsp;
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isEdit.disabled}
                      checked={isActive}
                      onChange={(e, value) => {
                        setValue("isActive", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is Active&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Address"}
            </MDTypography>
            <Grid container pt={3} spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="building"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      label="Building/Apartment"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="street"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      label="Street"
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      label="City"
                      autoComplete="off"
                      variant="outlined"
                      {...register("city", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      disabled={isEdit.disabled}
                      error={errors?.city ? true : false}
                      helperText={errors?.city ? errors.city.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      label="Province/State"
                      type="text"
                      autoComplete="off"
                      {...register("state", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.state ? true : false}
                      helperText={errors?.state ? errors.state.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      label="Country"
                      type="text"
                      autoComplete="off"
                      {...register("country", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.country ? true : false}
                      helperText={errors?.country ? errors.country.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      label="Zip Code"
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox
            pb={2}
            px={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setClient({});
                navigate("/clients");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          pb={2}
          px={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setClient({});
              navigate("/clients");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              navigate(`/clients/${id}/clientEncryption/edit`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      )}
    </>
  );
}

export default ClientBasicDetails;
