import Grid from "@mui/material/Grid";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import GetIndustryList from "hooks/IndustryList/GetIndustryList";
import GetStudyTypeList from "hooks/StudyTypeList/GetStudyTypeList";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetSurveyTypeList from "hooks/SurveyList/GetSurveyTypeList";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import GetCalculationList from "hooks/Calculation/GetCalculationList";
import GetListOfProjects from "hooks/ListOfProjects/GetListOfProjects";
import Autocomplete from "@mui/material/Autocomplete";

function BasicDetails() {
  const { language, languageMap } = GetLanguageList();
  const { industry } = GetIndustryList();
  const { studyType } = GetStudyTypeList();
  const { projects } = GetListOfProjects();
  const { surveyType } = GetSurveyTypeList();
  const { surveyStatusList } = GetSurveyStatusList();
  const { calculationList } = GetCalculationList();
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  let { isEdit, setIsEdit, setSurvey, survey, setLangId } = useOutletContext();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      surveyName: "",
      projectId: "",
      surveyStatus: "",
      loi: "",
      cpi: "",
      studyType: "",
      industryId: "",
      surveyTypeId: "",
      ir: "",
      surveyStartDate: new Date().toISOString().slice(0, 10),
      surveyEndDate: moment().add(15, "days").format("YYYY-MM-DD"),
      languageId: "",
      liveDate: "",
      sampleRequired: "",
      estimatedRevenue: "",
      sampleCalculationType: "",
      minMargin: 0.5,
      surveyLink: "",
      surveyTestLink: "",
      isMobileAllowed: true,
      isDesktopAllowed: true,
      isTabletAllowed: true,
      countryCode: "",
    },
  });

  const surveyStartDate = watch("surveyStartDate");
  //const defaultEndDate = startDate ? moment(startDate).add(15, 'days').format("YYYY-MM-DD") : '';
  //const defaultSurveyEndDate = surveyStartDate ? moment(surveyStartDate).add(15, 'days').format("YYYY-MM-DD") : '';

  //let surveyStartDate = watch("surveyStartDate");
  // let endDate = new Date();
  // endDate.setDate(endDate.getDate() + 15);
  // let surveyEndDate = endDate.toISOString().slice(0, 10);
  let sampleRequired = watch("sampleRequired");
  let cpi = watch("cpi");
  let isTabletAllowed = watch("isTabletAllowed");
  let isMobileAllowed = watch("isMobileAllowed");
  let isDesktopAllowed = watch("isDesktopAllowed");
  let projectId = watch("projectId");
  let surveyLink = watch("surveyLink");
  let surveyTestLink = watch("surveyTestLink");
  let languageId = watch("languageId");
  useEffect(() => {
    if (Object.keys(survey).length <= 0) {
      if (industry.length > 0) setValue("industryId", "30");
      if (language.length > 0) setValue("languageId", "1");
      if (calculationList.length > 0) setValue("sampleCalculationType", 1);
      if (surveyStatusList.length > 0) setValue("surveyStatus", 2);
      if (surveyType.length > 0) setValue("surveyTypeId", 1);
    }
    // eslint-disable-next-line
  }, [industry, calculationList, language, surveyType, surveyStatusList]);

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      setValue("surveyName", survey?.surveyName);
      setValue("surveyStartDate", moment(survey?.surveyStartDate).format("YYYY-MM-DD"));
      setValue("surveyEndDate", moment(survey?.surveyEndDate).format("YYYY-MM-DD"));
      setValue("projectId", survey?.projectId);
      setValue("surveyStatus", survey?.surveyStatusId);
      setValue("industryId", survey?.industryId);
      setValue("ir", survey?.ir);
      setValue("loi", survey?.loi);
      setValue("cpi", parseFloat(survey?.cpi).toFixed(2));
      setValue("studyType", survey?.sampleTypeId);
      setValue("surveyLink", survey?.surveyLink);
      setValue("surveyTestLink", survey?.surveyTestLink);
      setValue("sampleCalculationType", survey?.sampleCalculationType);
      setValue("minMargin", survey?.minMargin);
      setValue("estimatedRevenue", parseFloat(survey?.estimatedRevenue).toFixed(2));
      setValue("sampleRequired", survey?.sampleRequired);
      setValue("surveyTypeId", survey?.surveyTypeId);
      setValue("languageId", survey?.languageId.toString());
      setValue("isDesktopAllowed", survey?.isDesktopAllowed);
      setValue("isMobileAllowed", survey?.isMobileAllowed);
      setValue("isTabletAllowed", survey?.isTabletAllowed);
      setValue("countryCode", survey?.countryCode);
      setValue("estimatedRevenue", survey?.sampleRequired * survey?.cpi);
    }
    // eslint-disable-next-line
  }, [survey]);

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let params = Object.fromEntries(searchParams);
  const [paramsSearch, setParamsSearch] = useState();

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setParamsSearch(true);
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (paramsSearch) {
      setValue("projectId", params.project_id);
    }
    // eslint-disable-next-line
  }, [paramsSearch]);

  useEffect(() => {
    if (survey.studyType === "" || survey.studyType === undefined) {
      if (projectId !== "") {
        projects
          .filter((val) => {
            if (parseInt(projectId) === val.id) {
              return true;
            }
          })
          .map((val) => {
            setValue("studyType", val.projectTypeId.toString());
            return true;
          });
      }
    }
    // eslint-disable-next-line
  }, [projects, projectId]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  let getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/surveys/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/survey/${id}`);
          setSurvey(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      projectId: parseInt(data.projectId),
      surveyStatusId: parseInt(data.surveyStatus),
      loi: parseFloat(data.loi),
      cpi: parseFloat(data.cpi),
      surveyName: data.surveyName,
      sampleTypeId: parseInt(data.studyType),
      industryId: parseInt(data.industryId),
      surveyTypeId: parseInt(data.surveyTypeId),
      ir: parseFloat(data.ir),
      surveyStartDate: data.surveyStartDate,
      surveyEndDate: data.surveyEndDate,
      languageId: parseInt(data.languageId),
      sampleRequired: parseInt(data.sampleRequired),
      sampleCalculationType: parseInt(data.sampleCalculationType),
      minMargin: parseFloat(data.minMargin),
      isDesktopAllowed: data.isDesktopAllowed,
      isMobileAllowed: data.isMobileAllowed,
      isTabletAllowed: data.isTabletAllowed,
      surveyLink: data.surveyLink,
      surveyTestLink: data.surveyTestLink,
    };

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/survey/${survey.id}`, payload);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setSurvey({});
        setIsLoading(false);
        setLangId(data.languageId);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/surveys/${result.data.id}/qualification/edit`);
      } else {
        let result = await TLinkApi.post("/survey", payload);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setSurvey({});
        setLangId(data.languageId);
        navigate(`/surveys/${result.data.id}/qualification/edit`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let data = sampleRequired * cpi;
    setValue("estimatedRevenue", data);
    // eslint-disable-next-line
  }, [cpi, sampleRequired]);

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
          }}
          p={3}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/surveys/${id}/edit`);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox bgColor="#F8F9FF" p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="projectId"
                  control={control}
                  rules={{
                    required: "Project Id is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      label="Project Id"
                      required
                      error={errors?.projectId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Project Id</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        label="Project Id"
                        sx={selectStyle}
                        disabled={isEdit.disabled || isEdit.edit}
                        // autosize={true}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {projects.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.projectId && (
                        <FormHelperText>
                          {errors?.projectId ? errors.projectId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="studyType"
                  control={control}
                  rules={{
                    required: "Project Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.studyType ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Project Type</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        sx={selectStyle}
                        label="Project Type"
                      >
                        {studyType.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.studyType && (
                        <FormHelperText>
                          {errors?.studyType ? errors.studyType.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="surveyTypeId"
                  control={control}
                  rules={{
                    required: "Survey Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.surveyTypeId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Survey Type</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        label="Survey Type"
                        sx={selectStyle}
                      >
                        {surveyType.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.surveyTypeId && (
                        <FormHelperText>
                          {errors?.surveyTypeId ? errors.surveyTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="surveyName"
                  control={control}
                  rules={{
                    required: "Survey Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      label="Survey Name"
                      error={errors?.surveyName ? true : false}
                      helperText={errors?.surveyName ? errors.surveyName.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="languageId"
                  control={control}
                  rules={{
                    required: "language Field is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      disabled={isEdit.disabled}
                      onChange={(event, newValue) => {
                        onChange(newValue?.id);
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          minHeight: "20px",
                        },
                      }}
                      isOptionEqualToValue={(option, value) => option?.id === value}
                      options={language}
                      getOptionLabel={(option) =>
                        option.name
                          ? option.name
                          : languageMap[languageId]
                          ? languageMap[languageId]
                          : ""
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="outlined"
                          label="Market"
                          size="small"
                          required
                          disabled={isEdit.disabled}
                          error={errors?.languageId ? true : false}
                          helperText={errors?.languageId ? errors.languageId.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="industryId"
                  control={control}
                  rules={{
                    required: "Industry Field is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.industryId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>industry</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        label="industry"
                        sx={selectStyle}
                      >
                        {industry.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.industryId && (
                        <FormHelperText>
                          {errors?.industryId ? errors.industryId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <Divider mx={2} />
          <MDBox bgColor="#F8F9FF" p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="sampleRequired"
                  control={control}
                  rules={{
                    required: "Sample Required is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      disabled={isEdit.disabled}
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      onKeyPress={(e) => {
                        ingerNum(e);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      label="Sample Required"
                      error={errors?.sampleRequired ? true : false}
                      helperText={errors?.sampleRequired ? errors.sampleRequired.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="cpi"
                  control={control}
                  rules={{
                    required: "CPI is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      {...register("cpi", {
                        min: 0,
                      })}
                      type="number"
                      label="CPI"
                      error={errors?.cpi ? true : false}
                      helperText={
                        errors?.cpi && errors.cpi.message !== ""
                          ? errors.cpi.message
                          : errors?.cpi?.type === "min"
                          ? "Cpi should not be less then 0"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="ir"
                  control={control}
                  rules={{
                    required: "Incident Rate(IR) is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      {...register("ir", {
                        min: 0,
                      })}
                      label="Incident Rate(IR)"
                      error={errors?.ir ? true : false}
                      helperText={
                        errors?.ir && errors.ir.message !== ""
                          ? errors.ir.message
                          : errors?.ir?.type === "min"
                          ? "IR should not be less then 0"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="loi"
                  control={control}
                  rules={{
                    required: "LOI is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="number"
                      label="LOI"
                      {...register("loi", {
                        min: 0,
                      })}
                      error={errors?.loi ? true : false}
                      helperText={
                        errors?.loi && errors.loi.message !== ""
                          ? errors.loi.message
                          : errors?.loi?.type === "min"
                          ? "Loi should not be less then 0"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="estimatedRevenue"
                  control={control}
                  rules={{
                    required: "Estimated Revenue is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled
                      label="Estimated Revenue ($)"
                      error={errors?.estimatedRevenue ? true : false}
                      helperText={errors?.estimatedRevenue ? errors.estimatedRevenue.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="minMargin"
                  control={control}
                  rules={{
                    required: "Min Margin is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      {...register("minMargin", {
                        min: 0.5,
                        max: 1,
                      })}
                      type="number"
                      label="Min Margin"
                      error={errors?.minMargin ? true : false}
                      helperText={
                        errors?.minMargin && errors.minMargin.message !== ""
                          ? errors.minMargin.message
                          : errors?.minMargin?.type === "min" || errors?.minMargin?.type === "max"
                          ? "Min Margin between 0.5 to 1"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <Divider mx={2} />
          <MDBox bgColor="#F8F9FF" p={2} mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="surveyStartDate"
                  control={control}
                  rules={{
                    required: "Start Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Start Date"
                      error={errors?.surveyStartDate ? true : false}
                      helperText={errors?.surveyStartDate ? errors.surveyStartDate.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="surveyEndDate"
                  control={control}
                  rules={{
                    required: "End Date is required!",
                    validate: {
                      endDateGreaterThanStartDate: (value) => {
                        return (
                          value >= surveyStartDate || "End date cannot be less than start date"
                        );
                      },
                    },
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled || surveyStartDate === ""}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //value={value}

                      onChange={(e) => field.onChange(e.target.value)}
                      label="End Date"
                      error={!!errors?.surveyEndDate}
                      helperText={errors?.surveyEndDate?.message || null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="surveyStatus"
                  control={control}
                  rules={{
                    required: "Survey Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.surveyStatus ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel> Survey Status</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        label="Survey Status"
                        sx={selectStyle}
                      >
                        {surveyStatusList.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.surveyStatus && (
                        <FormHelperText>
                          {errors?.surveyStatus ? errors.surveyStatus.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} style={{ position: "relative" }}>
                <Controller
                  name="surveyLink"
                  control={control}
                  rules={{
                    required: "Survey Link is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      type="url"
                      variant="outlined"
                      label="Survey Url"
                      {...register("surveyLink", {
                        pattern: {
                          value:
                            // eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid Link",
                        },
                      })}
                      error={errors?.surveyLink ? true : false}
                      helperText={errors?.surveyLink ? errors.surveyLink.message : null}
                    />
                  )}
                />
                <Icon
                  style={{
                    position: "absolute",
                    right: 6,
                    bottom: errors?.surveyLink ? 27 : 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy(surveyLink);
                  }}
                >
                  copy
                </Icon>
              </Grid>
              <Grid item xs={12} md={12} lg={12} style={{ position: "relative" }}>
                <Controller
                  name="surveyTestLink"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      type="url"
                      variant="outlined"
                      label="Survey Test Url"
                      {...register("surveyTestLink", {
                        pattern: {
                          value:
                            // eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid Link",
                        },
                      })}
                      error={errors?.surveyTestLink ? true : false}
                      helperText={errors?.surveyTestLink ? errors.surveyTestLink.message : null}
                    />
                  )}
                />
                <Icon
                  style={{
                    position: "absolute",
                    right: 6,
                    bottom: errors?.surveyTestLink ? 27 : 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy(surveyTestLink);
                  }}
                >
                  copy
                </Icon>
              </Grid>
              <Grid item xs={12} md={6} display="flex" flexWrap="wrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMobileAllowed}
                      onChange={(e, value) => {
                        setValue("isMobileAllowed", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is MobileAllowed&nbsp;
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDesktopAllowed}
                      onChange={(e, value) => {
                        setValue("isDesktopAllowed", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is DesktopAllowed&nbsp;
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTabletAllowed}
                      onChange={(e, value) => {
                        setValue("isTabletAllowed", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is TabletAllowed&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled === false && (
          <MDBox
            display="flex"
            px={2}
            pb={2}
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setSurvey({});
                navigate("/surveys");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          px={2}
          pb={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setSurvey({});
              navigate("/surveys");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              navigate(`/surveys/${id}/qualification/edit`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default BasicDetails;
