import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import moment from "moment";
import {
  Icon, 
  IconButton,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetProjectsList from "hooks/ProjectList/GetProjectList";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import MDTypography from "components/MDTypography";
import { Stack } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import TLinkApi from "services/tlink.api";

function ProjectsList() {
  const navigate = useNavigate();
  const { setIsEdit } = useOutletContext();
  const { setIsDelete } = useOutletContext();
  // const {deleteIndex, setDeleteIndex} = useState(); 

  // const deleterow = async (Index) => {
  // try{
  //   axios.delete(TLinkApi.baseUrl + `/reconciliation/${Index}`)
  // }
  // catch (err) {
  //   // setFormIsLoading(false);
  //   // enqueueSnackbar(err?.response?.data?.message, {
  //   //   variant: "error",
  //   console.log(err);
  //   }
  // }
  

  const {
    projects,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
  } = GetProjectsList();

  let rows = projects;

  let columns = [
    {
      accessor: "id",
      Header: "Project Id",
      align: "left",
      Cell: ({ row }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsEdit({
              disabled: true,
              edit: false,
            });
            navigate(`/projects/${row.values.id}`);
          }}
        >
          {row.values.id}
        </div>
      ),
    },
    {
      accessor: "clientName",
      Header: "Client Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const clientName = row.original.clientName;
        const truncatedName =
          clientName.length > 15 ? clientName.substring(0, 15) + "..." : clientName;
        return (
          <Tooltip title={row.original.clientName}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "name",
      Header: "Project Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.values?.name || "";
        const truncatedName = name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => `${moment(params.row.original.startDate).format("DD-MM-YYYY")}`,
    },

    {
      accessor: "endDate",
      Header: "End Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => `${moment(params.row.original.endDate).format("DD-MM-YYYY")}`,
    },
    {
      accessor: "totalRevenue",
      Header: "Total Revenue",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const revenue = parseFloat(row?.values?.totalRevenue);
        const roundedRevenue = Math.round(revenue * 100) / 100;
        return roundedRevenue.toFixed(2);
      },
    },
    {
      accessor: "totalCost",
      Header: "Total Cost",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const cost = parseFloat(row?.values?.totalCost);
        const roundedCost = Math.round(cost * 100) / 100;
        return roundedCost.toFixed(2);
      },
    },
    {
      accessor: "status",
      Header: "Status",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return params.row.original.status === 1
          ? "Live"
          : params.row.original.status === 2
          ? "Closed"
          : params.row.original.status === 3
          ? "Awarded"
          : params.row.original.status === 4
          ? "Paused"
          : params.row.original.status === 5
          ? "Invoiced"
          : params.row.original.status;
      },
    },
    // {
    //   accessor: "action",
    //   Header: "Action",
    //   align: "center",
    //   headerAlign: "center",

    //   Cell: ({ row }) => (
    //     <Stack direction="row" spacing={2}>
    //       <IconButton
    //         variant="outlined"
    //         color="dark"
    //         size="small"
    //         onClick={() => {
    //           console.log(row)
    //           deleterow(row.values.id)
    //           // setDeleteIndex({
    //           //   disabled: true,
    //           //   delete: false,
    //           // });
    //           // navigate(`/projects/${row.values.id}`);
    //         }}
    //       >
    //         <Icon>delete_icon</Icon>
    //       </IconButton>
    //     </Stack>
    //   ),
    // },
  ];

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        <MDTypography color="textColor" ml="1%">
          Projects
        </MDTypography>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <FormControl
                  size="small"
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={projectStatusFilter}
                    onChange={(e, value) => {
                      setProjecStatusFilter(e.target.value);
                    }}
                    sx={{ width: "200px" }}
                    label="Status"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    <MenuItem value={1}>Live</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                    <MenuItem value={3}>Awarded</MenuItem>
                    <MenuItem value={4}>Paused</MenuItem>
                    <MenuItem value={5}>Invoiced</MenuItem>
                  </Select>
                </FormControl>
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/projects/new");
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  + New Project
                </MDButton>
              </Grid>
            </Grid>
            <DataTable
              table={{ columns, rows }}
              isSorted
              noEndBorder
              entriesPerPage={entriesPerPage}
              canSearch={false}
              tablePage={page}
              navigateRow={`/projects`}
              setTablePage={setPage}
              setEntriesPerPage={setEntriesPerPage}
              totalLength={totalLength}
              pagination={true}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ProjectsList;
