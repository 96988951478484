import { Autocomplete, Icon, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import QuotaQualificationChart from "comps/Cards/quotaQulificationChart";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";

const QuotaList = ({
  index,
  quotaIndex,
  setQuotaIndex,
  data,
  value,
  setData,
  quota,
  getQuotaQualificationList,
  getQuotaList,
  isEdit,
  setIsEdit,
}) => {
  let { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);

  const activeQualification = async ({ value, val }) => {
    try {
      let result = await TLinkApi.put(`survey/quota/isActive/${value.quota.id}`, {
        isActive: val,
      });
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      getQuotaQualificationList();
      setQuotaIndex("");
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const save = async (payload) => {
    if (!error) {
      let conditions = payload.quota.conditions.map((val) => {
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          return {
            qualificationId: val.qualificationId,
            answerId: val.answerId,
          };
        } else if (val.qualificationTypeId === 4) {
          let range = val.selectedRange.map((value) => {
            return `${value.min}-${value.max}`;
          });
          return {
            qualificationId: val.qualificationId,
            answerText: range,
          };
        } else {
          return {
            qualificationId: val.qualificationId,
            answerText: [val.text],
          };
        }
      });
      let surveyQuota = {
        surveyId: payload.id,
        quota: {
          name: payload.quota.name,
          sampleNumber: payload.quota.sampleNumber,
          conditions: conditions,
          isActive: true,
        },
      };

      try {
        let result = await TLinkApi.put(`survey/quota/${payload.quota.id}`, surveyQuota);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });

        getQuotaQualificationList();
        getQuotaList();
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (
        data[index]?.quota?.name === "" ||
        data[index]?.quota?.sampleNumber === "" ||
        data[index]?.quota?.conditions.length <= 0
      ) {
        setError(true);
      } else {
        let value = data[index]?.quota?.conditions.some((value) => {
          if (value?.answerId?.length <= 0 && value?.answerText?.length <= 0) {
            return true;
          } else if (value?.text === "") {
            return true;
          } else if (Array.isArray(value?.selectedRange) && value?.selectedRange?.length > 0) {
            let match = value?.selectedRange.some((rangeValue) => {
              if (
                rangeValue.min === "" ||
                rangeValue.max === "" ||
                Number(rangeValue.min) > Number(rangeValue.max) ||
                Number(rangeValue.min) < 10 ||
                Number(rangeValue.max) > 100
              ) {
                return true;
              } else {
                return false;
              }
            });
            return match;
          } else {
            return false;
          }
        });
        setError(value);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <MDBox border="1px solid #D9D9D9" borderRadius="10px" mt={3} key={index}>
      <MDBox
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="center"
        minHeight={"80px"}
        gap="10px"
        mb={1}
      >
        <MDBox
          width="5%"
          sx={{ width: { md: "10%", lg: "5%" } }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mt={2.5}
        >
          <IconButton
            sx={{ transform: index === quotaIndex ? "rotate(180deg)" : "" }}
            onClick={() => {
              if (index === quotaIndex) {
                setQuotaIndex("");
              } else {
                setQuotaIndex(index);
              }
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </MDBox>
        <MDBox width="70%" sx={{ width: { xs: "40%", md: "50%", lg: "70%" } }}>
          <MDBox
            style={{
              color: data[index]?.quota?.name === "" ? "red" : "#00ADEF",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.name === "" ? "Quota Name Required" : "Quota Name"}
          </MDBox>
          <MDInput
            fullWidth
            required
            disabled={isEdit.disabled === true}
            value={value.quota.name}
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.name = e.target.value;
              setData(payload);
            }}
            autoComplete="off"
            variant="outlined"
            type="text"
            placeholder="Quota Name"
            error={data[index]?.quota?.name === "" ? true : false}
          />
        </MDBox>
        <MDBox width="10%">
          <MDBox
            style={{
              color: data[index]?.quota?.sampleNumber === "" ? "red" : "#00ADEF",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.sampleNumber === ""
              ? "No. of Participants Required"
              : "No. of Participants"}
          </MDBox>
          <MDInput
            value={value.quota.sampleNumber}
            disabled={isEdit.disabled === true}
            fullWidth
            placeholder="No. Of Participants"
            onChange={(e) => {
              let payload = [...data];
              payload[index].quota.sampleNumber = e.target.value;
              setData(payload);
            }}
            required
            autoComplete="off"
            variant="outlined"
            type="number"
            error={data[index]?.quota?.sampleNumber === "" ? true : false}
          />
        </MDBox>

        <MDBox width="10%">
          <MDBox
            style={{
              color: "#00ADEF",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {"CPI"}
          </MDBox>
          <MDInput
            value={value.quota.quotaCpi}
            disabled
            fullWidth
            placeholder="CPI"
            required
            autoComplete="off"
            variant="outlined"
            type="number"
          />
        </MDBox>
        <MDBox
          width="10%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { md: "20%", lg: "10%" } }}
          mt={2.5}
        >
          <Switch
            checked={value.quota.isActive}
            disabled={isEdit.disabled === true}
            onChange={(e, val) => {
              activeQualification({ value, val });
            }}
          />
          <IconButton
            color="dark"
            size="small"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            <Icon>border_color_icon</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{ marginLeft: { md: "11%", lg: "5.2%" }, width: { md: "68%", lg: "85%" } }}
        display={index === quotaIndex ? "" : "none"}
        mb={1}
      >
        <Autocomplete
          multiple
          limitTags={1}
          disabled={isEdit.disabled === true}
          value={value.quota.conditions}
          onChange={(e, newValue) => {
            let payload = [...data];
            payload[index].quota.conditions = newValue;
            setData(payload);
          }}
          options={quota}
          getOptionLabel={(option) =>
            option?.question?.questionText ? option.question.questionText : ""
          }
          isOptionEqualToValue={(option, value) =>
            option?.question?.questionText === value?.question?.questionText
          }
          sx={{
            "& .MuiInputBase-input": {
              minHeight: "20px",
            },
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              placeholder="Question List"
              size="small"
              error={data[index]?.quota?.conditions.length <= 0 ? true : false}
              helperText={data[index]?.quota?.conditions.length <= 0 ? "Question Required" : ""}
            />
          )}
        />
        {value?.quota?.conditions?.map((val, i) => (
          <QuotaQualificationChart
            key={i}
            val={val}
            value={value}
            data={data}
            quota={quota}
            index={i}
            dataIndex={index}
            setData={setData}
            setError={setError}
            isEdit={isEdit}
          />
        ))}
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          mt={1}
        >
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            disabled={isEdit.disabled === true}
            onClick={() => {
              save(value);
            }}
          >
            Edit Save
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default QuotaList;
