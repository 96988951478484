import Grid from "@mui/material/Grid";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import GetStudyTypeList from "hooks/StudyTypeList/GetStudyTypeList";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import Auth from "services/auth";
import PrincipalState from "services/principal.state";
function BasicDetails() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const { projectManager, setSearch, search } = GetProjectManagerList();
  const { isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const { clients } = GetListOfClients();
  const [isLoading, setIsLoading] = useState(false);
  const { studyType } = GetStudyTypeList();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      name: "",
      clientId: "",
      isActive: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(15, "days").format("YYYY-MM-DD"),
      projectManager: "",
      status: 3,
      projectTypeId: "",
    },
  });

  let startDate = watch("startDate");
  let isActive = watch("isActive");

  let { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) setCurrentUser(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    await authorize();
  }, []);

  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/projects/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/projects/${id}`);
          if (result) {
            setValue("name", result.data.name);
            setValue("projectManager", result.data.projectManager);
            setValue("startDate", moment(result.data.startDate).format("YYYY-MM-DD"));
            setValue("endDate", moment(result.data.endDate).format("YYYY-MM-DD"));
            setValue("status", result.data.status);
            setValue("clientId", result.data.clientId);
            setValue("projectTypeId", result.data.projectTypeId);
            setValue("isActive", result.data.isActive);
            setIsLoading(false);
          }
        } catch (e) {
          setIsLoading(false);
          console.log(e);
        }
      }
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/projects/${id}`, data);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/projects/${id}/surveyList`);
      } else {
        let result = await TLinkApi.post("/projects", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/projects/${result.data.id}/surveyList`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
            minHeight: "700px",
          }}
          p={2}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2} px={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/projects/${id}/edit`);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mx={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
              mb={2}
            >
              {"Basic Details"}
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="clientId"
                  control={control}
                  rules={{
                    required: "Client is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.clientId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Client Id</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Client Id"
                        sx={selectStyle}
                        disabled={isEdit.edit || isEdit.disabled}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {clients.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.clientId && (
                        <FormHelperText>
                          {errors?.clientId ? errors.clientId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="projectTypeId"
                  control={control}
                  rules={{
                    required: "Project Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.projectTypeId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Project Type</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        label="Project Type"
                        sx={selectStyle}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {studyType.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.projectTypeId && (
                        <FormHelperText>
                          {errors?.projectTypeId ? errors.projectTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Project Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      label="Project Name"
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.name ? true : false}
                      helperText={errors?.name ? errors.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="projectManager"
                  control={control}
                  rules={{
                    required: "Project Manager is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      error={errors?.projectManager ? true : false}
                      required
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Project Manager</InputLabel>
                      <Select
                        value={value}
                        sx={selectStyle}
                        onChange={onChange}
                        label="Project Manager"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        onClose={() => setSearch("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            required
                            value={search}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        {projectManager?.map((value) => (
                          <MenuItem value={value.email} key={value.id}>
                            {value.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.projectManager && (
                        <FormHelperText>
                          {errors?.projectManager ? errors.projectManager.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: "Start Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Start Date"
                      autoComplete="off"
                      variant="outlined"
                      type="date"
                      error={errors?.startDate ? true : false}
                      helperText={errors?.startDate ? errors.startDate.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: "End Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="End Date"
                      autoComplete="off"
                      variant="outlined"
                      type="date"
                      disabled={isEdit.disabled || startDate === ""}
                      //value={value}
                      sx={selectStyle}
                      onChange={(e) => field.onChange(e.target.value)}
                      {...register("endDate", {
                        min: startDate ? new Date(startDate).toISOString().split("T")[0] : "",
                      })}
                      error={errors?.endDate ? true : false}
                      helperText={
                        errors?.endDate && errors.endDate.message !== ""
                          ? errors.endDate.message
                          : errors?.endDate?.type === "min"
                          ? "End date cannot be earlier than the start date"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={{
                    required: "Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.status ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        sx={selectStyle}
                        label="Status"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Live</MenuItem>
                        <MenuItem value={2}>Closed</MenuItem>
                        <MenuItem value={3}>Awarded</MenuItem>
                        <MenuItem value={4}>Paused</MenuItem>
                        <MenuItem value={5}>Invoiced</MenuItem>
                      </Select>
                      {errors?.status && (
                        <FormHelperText>
                          {errors?.status ? errors.status.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              {(currentUser?.roleId === "1") | (currentUser?.roleId === 1) ? (
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isEdit.disabled}
                        checked={isActive}
                        onChange={(e, value) => {
                          setValue("isActive", value);
                        }}
                      />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Is Active&nbsp;
                      </MDTypography>
                    }
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox
            px={2}
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                navigate("/projects");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              {isEdit.edit === true ? "Save Project" : "Add Project"}
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <>
          <MDBox
            px={2}
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                navigate("/projects");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                navigate(`/projects/${id}/surveyList`);
              }}
            >
              Next
            </MDButton>
          </MDBox>
        </>
      )}
    </>
  );
}

export default BasicDetails; 