import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import GetListOfClients from "../../../../hooks/ListOfClients/GetListOfClients";
import GetBuyerList from "../../../../hooks/ClientBuyerList/GetBuyerList";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";

export default function ClientBuyerFormModal({
  open,
  handleClose,
  editData,
  getData,
  data,
}) {
  const [isEdit, setIsEdit] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      clientId: "",
      buyerId: [],
      allowed: true,
    },
  });

  let { clients } = GetListOfClients();
  let { buyerList } = GetBuyerList(watch("clientId"));
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();
  let [deleteitem, setDeleteItem] = [];

  const onSubmit = async (data) => {
    data.isActive = true;
    data.vendorId = parseInt(id);
    data.allowed = data.allowed;
    if (isEdit) {
      let payload = {
        buyerId: data.buyerId.map((val) => val.id),
        allowed: data.allowed,
      };
      try {
        let result = await TLinkApi.put(
          `vendors/${id}/client/buyers/${data.clientId}`,
          payload
        );
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        handleClose();
        resetForm();
        getData();
        setIsEdit(false); 
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    } else {
      try {
        let result = await TLinkApi.post(`vendors/client/buyers`, data);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        handleClose();
        resetForm();
        getData();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  const resetForm = () => {
    reset({
      clientId: "",
      buyerId: [],
      allowed: true,
    });
  };
  const handlechipdelete = (value) => {
    let index = editData.buyerId.map((data) => data.id).indexOf(value.id);
    editData.buyerId.splice(index, 1);
    setValue("buyerId", editData?.buyerId ? editData?.buyerId : []);
  };

  React.useEffect(() => {
    if (editData) {
      setValue("allowed", editData?.allowed);
      setValue("buyerId", editData?.buyerId ? editData?.buyerId : []);
      setValue("clientId", editData?.clientId.toString());
      setIsEdit(true);
    }
  }, [editData]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let selectStyle = {
    minHeight: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <h5 style={{ color: "#595959" }}>
            {!isEdit ? "Add Row" : "Edit Row"}
          </h5>
          <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormControl
              fullWidth
              required
              disabled={isEdit}
              error={errors?.clientId ? true : false}
              sx={{
                label: {
                  color: "rgba(0, 0, 0, 0.25)",
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <InputLabel>Client Id</InputLabel>
              <Select
                sx={selectStyle}
                value={watch("clientId")}
                {...register("clientId", {
                  required: "Client Id is Required !",
                })}
                label="Client Id"
              >
                {clients
                  .filter((value) => {
                    let found = data.find(
                      (val) => val.clientId === value.id && !isEdit
                    );
                    if (found) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .map((value, i) => {
                    return (
                      <MenuItem value={value.id} key={i}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {errors?.clientId && !watch("clientId") && (
                <FormHelperText>
                  {errors?.clientId ? errors.clientId.message : null}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              required
              error={errors?.buyerId ? true : false}
              sx={{
                marginTop: "10px",
                label: {
                  color: "rgba(0, 0, 0, 0.25)",
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <InputLabel>Buyer Id</InputLabel>
              {!isEdit && (
                <Select
                  multiple
                  sx={selectStyle}
                  value={watch("buyerId")?.length > 0 ? watch("buyerId") : []}
                  {...register("buyerId", {
                    required: "Buyer Id is Required !",
                  })}
                  label="Buyer Id"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box
                    sx={{
                      display: "flex",
                      overflowX: "auto",
                      maxHeight: "250px",
                      WebkitOverflowScrolling: "touch",
                      // "&::-webkit-scrollbar": {
                      //   display: "none",
                      // },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                    >
                      {selected?.map((value) => {
                        return (
                          <Chip
                            label={
                              value?.id
                                ? buyerList.find(
                                    (val) =>
                                      Number(val.buyerId) === Number(value.id)
                                  )?.buyerName
                                : value
                                ? buyerList.find(
                                    (val) =>
                                      Number(val.buyerId) === Number(value)
                                  )?.buyerName
                                : ""
                            }
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {buyerList.map((value, i) => {
                    return (
                      <MenuItem value={value?.buyerId} key={i}>
                        {value?.buyerName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {isEdit && (
                <Select
                  multiple
                  sx={selectStyle}
                  value={watch("buyerId")?.length > 0 ? watch("buyerId") : []}
                  {...register("buyerId", {
                    required: "Buyer Id is Required !",
                  })}
                  label="Buyer Id"
                  onChange={(e, value) => {
                    let obj = {
                      id: value?.props?.value,
                      name: value?.props?.children,
                    };
                    editData?.buyerId?.push(obj);
                    setValue(
                      "buyerId",
                      editData?.buyerId ? editData?.buyerId : []
                    );
                  }}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        overflowX: "auto",
                        maxWidth: "90%",
                        maxHeight: "250px",
                        WebkitOverflowScrolling: "touch",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                    >
                      {selected?.map((value) => {
                        return (
                          <Chip
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            label={
                              value?.id
                                ? buyerList.find(
                                    (val) =>
                                      Number(val.buyerId) === Number(value.id)
                                  )?.buyerName
                                : value
                                ? buyerList.find(
                                    (val) =>
                                      Number(val.buyerId) === Number(value)
                                  )?.buyerName
                                : ""
                            }
                            onDelete={() => handlechipdelete(value)}
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {buyerList.map((value, i) => {
                    let index = editData?.buyerId
                      .map((data) => data.id)
                      .indexOf(value?.buyerId);
                    if (index === -1 && isEdit) {
                      return (
                        <MenuItem value={value?.buyerId} key={i}>
                          {value?.buyerName}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              )}
              {errors?.buyerId && (
                <FormHelperText>
                  {errors?.buyerId ? errors.buyerId.message : null}
                </FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <MDButton
              color="text"
              variant="outlined"
              onClick={() => {
                handleClose();
                setIsEdit(false);
                resetForm();
              }}
            >
              Cancel
            </MDButton>
            <MDButton color="info" variant="contained" type="submit">
              {!isEdit ? "Add " : "Save"}
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
