import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetTopSurveyList = (timeline) => {
  const [topSurveys, setTopSurveys] = useState([]);
  const getTopSurveys = async () => {
    try {
      let data = await TLinkApi.post("dashboards/list/top-survey", timeline);
      setTopSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTopSurveys();
    // eslint-disable-next-line
  }, [timeline]);
  return { topSurveys, getTopSurveys };
};

export default GetTopSurveyList;
