import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import GetSurveyList from "hooks/surveyListWithProjectId/GetSurveyBasicList";
import Tooltip from "@mui/material/Tooltip";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
// import GetListOfClients from "hooks/ListOfClients/GetListOfClients";

function SurveyList() {
  const navigate = useNavigate();
  // const { clients } = GetListOfClients();
  const { setIsEdit } = useOutletContext();
  let { id } = useParams();
  const {
    surveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    filter,
    entriesPerPage,
    setEntriesPerPage,
    // setClientFilter,
    // clientFilter,
  } = GetSurveyList(id, -1);
  const { surveyStatusList } = GetSurveyStatusList();

  useEffect(() => {
    setIsEdit({
      disabled: false,
      edit: true,
    });
    // eslint-disable-next-line
  }, []);

  let rows = surveys;

  let columns = [
    {
      accessor: "id",
      Header: "Survey Id",
      align: "center",
    },
    {
      accessor: "surveyName",
      Header: "Survey Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.values?.surveyName || "";
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={row?.values?.surveyName}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "projectName",
      Header: "Project Name",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.values?.projectName || "";
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={row?.values?.projectName}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "sampleRequired",
      Header: "Sample Required",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "loi",
      Header: "Loi",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "ir",
      Header: "IR",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "cpi",
      Header: "Cpi",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(row?.values?.cpi).toFixed(2);
      },
    },

    {
      accessor: "starts",
      Header: "Starts",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "completes",
      Header: "Completes",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "epc",
      Header: "EPC",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(
          row?.original?.starts <= 0 ? 0 : row.original.revenue / row?.original?.starts
        ).toFixed(2);
      },
    },
    {
      accessor: "conversion",
      Header: "Conversion",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(
          row?.original?.starts <= 0 ? 0 : row.original.completes / row?.original?.starts
        ).toFixed(2);
      },
    },
    {
      accessor: "clientOverallEpc",
      Header: "Client Epc",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(row?.original?.clientOverallEpc).toFixed(2);
      },
    },
    {
      accessor: "clientOverallConversion",
      Header: "client Conversion",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(row?.original?.clientOverallConversion).toFixed(2);
      },
    },
    {
      accessor: "revenue",
      Header: "Revenue",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const revenue = parseFloat(row?.values?.revenue);
        const roundedRevenue = Math.round(revenue * 100) / 100;
        return roundedRevenue.toFixed(2);
      },
    },

    {
      accessor: "surveyStatusName",
      Header: "Status",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
  ];

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2} pb={3}>
              <Grid
                px={2}
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                {/* <Grid item xs={12} md={2} pr={3}>
                  <FormControl fullWidth>
                    <InputLabel>Clients</InputLabel>
                    <Select
                      value={clientFilter}
                      label="Clients"
                      onChange={(e) => {
                        setClientFilter(e.target.value);
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {clients?.map((value, i) => (
                        <MenuItem value={value.id} key={i}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} md={3} pr={3}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={filter}
                      label="Status"
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {surveyStatusList?.map((value, i) => {
                        return (
                          <MenuItem key={i} value={value.id}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <SearchInput setSearch={setSearch} search={search} />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate(`/surveys/new?project_id=${id}`);
                  }}
                >
                  + New Survey
                </MDButton>
              </Grid>
            </Grid>
            <DataTable
              table={{ columns, rows }}
              isSorted
              noEndBorder
              entriesPerPage={entriesPerPage}
              canSearch={false}
              navigateRow={"/surveys"}
              tablePage={page}
              setTablePage={setPage}
              setEntriesPerPage={setEntriesPerPage}
              totalLength={totalLength}
              pagination={true}
              tableMaxHeight={"calc(100vh - 160px)"}
            />
          </MDBox>
        </MDBox>
        <MDBox
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="text"
            onClick={() => {
              navigate(`/projects`);
            }}
          >
            cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              navigate(`/projects/${id}/reconciliations`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default SurveyList;
