import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useForm, Controller } from "react-hook-form";
import MinMax from "comps/Cards/InputFields";
import QuestionBox from "comps/Cards/Checkbox";
import { useSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QualificationList from "./components/qualificationList";
import AnswerText from "comps/Cards/ansText";

function QualificationSurvey() {
  let { setIsEdit, setSurvey, langId, setLangId, isEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  let navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [qualificationQuestions, setQualificationQuestions] = useState([]);
  const [qualificationQuestionList, setQualificationQuestionList] = useState([]);
  const [qualificaionId, setQualificaionId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [quesLoading, setQuesLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [height, setHeight] = useState(false);
  const [submitQualification, setSubmitQualification] = useState(false);
  const [qualificationError, setQualificaionError] = useState(false);

  const getQualificationList = async (query) => {
    setQuesLoading(true);
    try {
      let data = await TLinkApi.get(`/qualifications/${langId}?query=${query}`);
      let tempQualificationQuestionList = data
        .filter((qualification) => {
          if (
            qualification?.qualificationQuestion === null ||
            qualification.qualificationQuestion?.answers?.length <= 0 ||
            qualificaionId.includes(parseInt(qualification.id))
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map((qualification) => {
          if (qualification.qualificationTypeId === 1 || qualification.qualificationTypeId === 2) {
            qualification.options = qualification.qualificationQuestion.answers?.map((answer) => {
              return {
                id: answer.id,
                label: answer.qualificationAnswerDesc,
              };
            });
            qualification.selectedAnswers = [];
          } else if (qualification.qualificationTypeId === 3) {
            qualification.text = "text";
          } else if (qualification.qualificationTypeId === 4) {
            qualification.selectedRange = [
              {
                min: "10",
                max: "100",
              },
            ];
          }
          return qualification;
        });
      setQualificationQuestionList(tempQualificationQuestionList);
      setQuesLoading(false);
    } catch (e) {
      setQuesLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) getQualificationQuestions();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (langId !== "" || qualificaionId.length > 0) {
      if ((query !== undefined || query !== "") && query.length > 2) {
        getQualificationList(query);
      } else {
        getQualificationList(query);
      }
    }
    // eslint-disable-next-line
  }, [langId, qualificaionId, query]);

  useEffect(() => {
    getLanguageId();
    // eslint-disable-next-line
  }, [id]);

  const getLanguageId = async () => {
    if (id) {
      try {
        let result = await TLinkApi.get(`/survey/${id}`);
        setLangId(result.data.languageId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getQualificationQuestions = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/qualification/conditions`);
      let qualificationId = result?.qualifications.map(
        (qualification) => qualification.qualificationId
      );
      setQualificaionId(qualificationId);
      if (result?.qualifications) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      let data = result?.qualifications?.map((question) => {
        if (question.qualificationTypeId === 4) {
          if (question.qualificationTypeId === 4) {
            let ranges = question.answerText.map((value) => {
              let rangeValue = value.split("-");
              let payload = {
                min: parseInt(rangeValue[0]),
                max: parseInt(rangeValue[1]),
              };
              return payload;
            });
            question.range = ranges;
            question.selectedRange = ranges;
          }
        } else if (question.qualificationTypeId === 3) {
          let tempSplitValue = question.answerText;
          question.range = tempSplitValue;
          question.text = tempSplitValue;
        }
        return question;
      });

      setQualificationQuestions(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsEdit({
        disabled: false,
        edit: true,
      });
      console.log(e);
    }
  };

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      questionStatus: "standard",
      searchStatus: null,
    },
  });

  let questionStatus = watch("questionStatus");
  let searchStatus = watch("searchStatus");

  const handleCheck = () => {
    if (searchStatus) {
      if (searchStatus.qualificationTypeId === 1 || searchStatus.qualificationTypeId === 2) {
        return <QuestionBox question={searchStatus} updateQuestion={setValue} />;
      } else if (searchStatus.qualificationTypeId === 4) {
        return <MinMax question={searchStatus} updateQuestion={setValue} />;
      } else {
        return <AnswerText question={searchStatus} updateQuestion={setValue} />;
      }
    }
  };

  const save = async () => {
    if (!qualificationError) {
      let data = {};
      if (searchStatus.qualificationTypeId === 1 || searchStatus.qualificationTypeId === 2) {
        data = {
          qualificationId: searchStatus.id,
          isActive: true,
          answerId: searchStatus.selectedAnswers,
          qualificationTypeId: searchStatus.qualificationTypeId,
        };
      } else if (searchStatus.qualificationTypeId === 4) {
        let range = searchStatus?.selectedRange?.map((value) => {
          return `${value.min}-${value.max}`;
        });
        if (range.length === searchStatus.selectedRange.length) {
          data = {
            qualificationId: searchStatus.id,
            answerText: range,
            isActive: true,
            qualificationTypeId: searchStatus.qualificationTypeId,
          };
        }
      } else {
        data = {
          qualificationId: searchStatus.id,
          answerText: [`${searchStatus.text}`],
          isActive: true,
          qualificationTypeId: searchStatus.qualificationTypeId,
        };
      }

      let payload = {
        qualifications: data,
        surveyId: id,
      };

      try {
        await TLinkApi.post("/survey/qualification/conditions", payload);
        enqueueSnackbar("Created", {
          variant: "success",
        });
        setValue("searchStatus", null);
        setShowDropdown(!showDropdown);
        getQualificationQuestions();
        getQualificationList(query);
        setSubmitQualification(false);
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (searchStatus?.selectedAnswers?.length <= 0) {
      setQualificaionError(true);
    } else if (searchStatus?.text === "") {
      setQualificaionError(true);
    } else if (searchStatus?.selectedRange?.length > 0) {
      let error = searchStatus?.selectedRange?.some(
        (value) =>
          value.min === "" ||
          value.max === "" ||
          Number(value.min) > Number(value.max) ||
          Number(value.min) < 10 ||
          Number(value.max) > 100
      );
      setQualificaionError(error);
    } else {
      setQualificaionError(false);
    }
  }, [searchStatus]);

  return (
    <MDBox
      style={{
        borderRadius: "16px",
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form>
        <MDBox
          m={2}
          bgColor="white"
          p={2}
          style={{
            borderRadius: "16px",
            height: `700px`,
            overflowY: "auto",
          }}
        >
          <MDBox mt={1}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                md={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap="5px"
                style={{
                  background: "#f0f0f0",
                  height: "47px",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
                }}
              >
                <MDBox
                  display="block"
                  sx={{ width: { xs: "10%", md: "10%", lg: "5%" } }}
                  style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                ></MDBox>
                <MDBox
                  width="15%"
                  px={1}
                  color="textColor"
                  sx={{
                    width: {
                      xs: "10%",
                      md: "20%",
                      lg: "15%",
                    },
                    fontSize: { xs: "10px", md: "12px", lg: "15px" },
                  }}
                  style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                  }}
                >
                  Question Type
                </MDBox>
                <MDBox
                  width="70%"
                  px={1}
                  color="textColor"
                  sx={{
                    width: { xs: "40%", md: "50%", lg: "70%" },
                    fontSize: { xs: "10px", md: "12px", lg: "15px" },
                  }}
                  style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                >
                  Question
                </MDBox>
                <MDBox
                  width="10%"
                  color="textColor"
                  style={{ borderleft: "1px solid rgba(0, 0, 0, 0.06)" }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: { xs: "20%", md: "20%", lg: "10%" },
                  }}
                >
                  Action
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {qualificationQuestions.map((value, index) => (
            <QualificationList
              key={index}
              val={value}
              index={index}
              getQualificationQuestions={getQualificationQuestions}
              questionStatus={questionStatus}
              qualificationQuestionList={qualificationQuestionList}
              isEdit={isEdit}
              setQualificationQuestions={setQualificationQuestions}
              qualificationQuestions={qualificationQuestions}
              setIsEdit={setIsEdit}
            />
          ))}

          <MDBox mt={3} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              style={{
                height: "47px",
                fontSize: "14px",
                fontWeight: "500",
                color: "black",
              }}
            >
              <MDBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: { xs: "10%", md: "10%", lg: "5%" } }}
                style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
              >
                <IconButton
                  sx={{ transform: height === true ? "rotate(180deg)" : "" }}
                  onClick={() => {
                    if (height === false) {
                      setHeight(true);
                    } else {
                      setHeight(false);
                    }
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </MDBox>
              <MDBox
                width="15%"
                px={1}
                sx={{
                  width: {
                    xs: "10%",
                    md: "20%",
                    lg: "15%",
                  },
                }}
                style={{
                  borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                }}
              >
                <Controller
                  name="questionStatus"
                  control={control}
                  rules={{
                    required: "Question Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.questionStatus ? true : false}
                      size="small"
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Language List"
                        sx={{
                          height: "36.5px",

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                          "& :hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                        }}
                      >
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="customer">Custom</MenuItem>
                      </Select>
                      {errors?.questionStatus && (
                        <FormHelperText>
                          {errors?.questionStatus ? errors.questionStatus.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </MDBox>
              <MDBox
                px={1}
                width="70%"
                sx={{
                  width: { xs: "40%", md: "50%", lg: "70%" },
                }}
                style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
              >
                {questionStatus === "standard" && (
                  <Controller
                    name="searchStatus"
                    control={control}
                    rules={{
                      required: "Question Status is required!",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={qualificationQuestionList}
                        getOptionLabel={(option) => option?.qualificationQuestion?.questionText}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        value={value}
                        onChange={(e, value) => {
                          onChange(JSON.parse(JSON.stringify(value)));
                          setHeight(true);
                        }}
                        loading={quesLoading}
                        onInputChange={(_, val) => {
                          if (val !== undefined) {
                            setQuery(val);
                          } else {
                            setQuery("");
                          }
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option?.qualificationQuestion?.questionText}
                            </li>
                          );
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "1.2rem",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Question List" size="small" />
                        )}
                      />
                    )}
                  />
                )}
              </MDBox>
              <MDBox
                width="10%"
                style={{ borderleft: "1px solid rgba(0, 0, 0, 0.06)" }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: { xs: "20%", md: "20%", lg: "10%" },
                }}
              >
                <Switch
                  color="secondary"
                  checked={submitQualification}
                  // onChange={(e, value) => {
                  //   if (value === true) {
                  //     setSubmitQualification(true);
                  //     onSubmit();
                  //   } else {
                  //     setSubmitQualification(false);
                  //   }
                  // }}
                />
                {/* <IconButton>
                      <IndeterminateCheckBoxOutlinedIcon />
                    </IconButton> */}
              </MDBox>
            </MDBox>
            <MDBox style={{ display: height === false && "none" }}>
              {handleCheck()}
              {searchStatus !== null && (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  mt={2}
                  gap="10px"
                >
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => {
                      save();
                    }}
                  >
                    Add
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </form>
      <MDBox display="flex" alignItems="center" justifyContent="flex-end" m={2} gap="10px">
        <MDButton
          variant="outlined"
          color="dark"
          onClick={() => {
            navigate(`/surveys`);
            setSurvey({});
            setIsEdit({ disabled: false, edit: false });
          }}
        >
          cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            setIsEdit({ disabled: false, edit: false });
            setSurvey({});
            navigate(`/surveys/${id}/quota/edit`);
          }}
        >
          Next
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default QualificationSurvey;
