import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [projectMap, setProjectMap] = useState();
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [projectStatusFilter, setProjecStatusFilter] = useState(1);

  const getProjects = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/projects?search=${search}&page=${page}&limit=${entriesPerPage}&status=${projectStatusFilter}`
      );
      setProjects(data.data.projectData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
      let projectsMap = [];
      data?.data?.projectData?.map((value) => {
        projectsMap[value.id] = value.name;
        return true;
      });
      setProjectMap(projectsMap);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [page, search, entriesPerPage, projectStatusFilter]);
  return {
    projects,
    getProjects,
    projectMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
  };
};

export default GetProjectsList;
