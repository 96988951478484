import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetSurveyBasicList from "hooks/SurveyBasicList/GetSurveyBasicList";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import Union2 from "assets/images/Union2.png";
function SurveyList() {
  const navigate = useNavigate();
  const {
    surveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    setClientFilter,
    clientFilter,
    filter,
    entriesPerPage,
    setEntriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    setIsLoading,
  } = GetSurveyBasicList(2, -1);
  const { setSurvey, setIsEdit } = useOutletContext();
  const { clients } = GetListOfClients();
  const { surveyStatusList } = GetSurveyStatusList();

  let rows = surveys;

  let columns = [
    {
      accessor: "id",
      Header: "Id",
      align: "center",
      sortable: true,
      Cell: ({ row }) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/surveys/${row.values.id}`);
            }}
          >
            {row?.original?.id}
          </div>
        );
      },
    },

    // {
    //   accessor: "surveyName",
    //   Header: "Name",
    //   sortable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   Cell: ({ row }) => {
    //     const name = row?.original?.surveyName;
    //     const truncatedName = name.length > 25? name.substring(0, 25) + "..." : name;
    //     return (
    //       <Tooltip title={name}>
    //         <div
    //           onClick={() => {
    //             setIsEdit({
    //               disabled: true,
    //               edit: false,
    //             });
    //             navigate(`/surveys/${row.values.id}`);
    //           }}
    //         >
    //           {truncatedName}
    //           <br />

    //         </div>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      accessor: "surveyName",
      Header: "Survey Name",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.surveyName;
        // const truncatedName = name.length > 10 ? name.substring(0, 10) + "..." : name;
        return (
          <Tooltip title={name}>
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                maxWidth: "120px", // Adjust the maximum width as needed
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsEdit({
                  disabled: true,
                  edit: false,
                });
                navigate(`/surveys/${row.values.id}`);
              }}
            >
              {name}
            </div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "buyerName",
      Header: "Buyer Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.values?.buyerName || "";
        const truncatedName = name.length > 7 ? name.substring(0, 7) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "sampleRequired",
      Header: "Samp. Req.",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "revenue",
      Header: "Rev.",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const revenue = parseFloat(row?.values?.revenue);
        const roundedRevenue = Math.round(revenue * 100) / 100;
        return roundedRevenue.toFixed(2);
      },
    },
    {
      accessor: "loi",
      Header: <div style={{ fontSize: "10px", paddingLeft: "", position: "relative" }}>Loi</div>,
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "ir",
      Header: "IR",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "cpi",
      Header: "CPI",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const cpi = parseFloat(row?.values?.cpi);
        const roundedCpi = Math.round(cpi * 100) / 100;
        return roundedCpi.toFixed(2);
      },
    },

    {
      accessor: "starts",
      Header: "S",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "completes",
      Header: "Comp.",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "epc",
      Header: "EPC",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        return parseFloat(
          row?.original?.starts <= 0 ? 0 : row.original.revenue / row?.original?.starts
        ).toFixed(2);
      },
    },
    {
      accessor: "conversion",
      Header: "Conv(%)",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        return (
          <div style={{ width: "20px" }}>
            {parseFloat(
              row?.original?.starts <= 0 ? 0 : row.original.completes / row?.original?.starts
            ).toFixed(2)}
          </div>
        );
      },
    },
    {
      accessor: "clientOverallEpc",
      Header: "Cli. Epc",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        const epc = row?.original?.clientOverallEpc;
        return epc !== null && epc !== undefined ? parseFloat(epc).toFixed(2) : "NA";
      },
    },
    {
      accessor: "clientOverallConversion",
      Header: "Cli. conv.",
      align: "center",
      sortable: false,
      headerAlign: "center",
      Cell: ({ row }) => {
        const conversion = row?.original?.clientOverallConversion;
        return conversion !== null && conversion !== undefined
          ? parseFloat(conversion).toFixed(2)
          : "NA";
      },
    },
    {
      accessor: "countryCode",
      Header: "Country",
      align: "center",
      sortable: false,
      headerAlign: "center",
    },
    {
      accessor: "createdAt",
      Header: "Created at",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const createdAtTime = moment(row?.values?.createdAt);

        const formattedDateTime = createdAtTime.format("Do MMM YYYY, h:mm:ss a");
        const truncatedName =
          formattedDateTime.length > 15
            ? formattedDateTime.substring(0, 15) + "..."
            : formattedDateTime;
        return (
          <Tooltip title={formattedDateTime}>
            <div style={{ width: "50px" }}>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "lastCompleted",
      Header: "Last Complete",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const lastCompletedTime = moment(row?.values?.lastCompleted);
        if (!lastCompletedTime.isValid() || row?.values?.lastCompleted === null) {
          return <div>NA</div>;
        }
        const formattedDateTime = lastCompletedTime.format("Do MMM YYYY, h:mm:ss a");
        const truncatedName =
          formattedDateTime.length > 15
            ? formattedDateTime.substring(0, 15) + "..."
            : formattedDateTime;
        return (
          <Tooltip title={formattedDateTime}>
            <div style={{ width: "50px" }}>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "surveyStatusName",
      Header: "Status",
      align: "center",
      sortable: false,
      headerAlign: "center",
    },
  ];
  return (
    <MDBox width="100%">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDTypography color="textColor" ml="1%">
        Surveys
      </MDTypography>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} my={3} borderRadius="5px" bgColor="white" width="100%">
          <Grid container spacing={2} pb={3}>
            <Grid
              px={2}
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <Grid item xs={12} md={2} pr={3}>
                <FormControl fullWidth>
                  <InputLabel>Clients</InputLabel>
                  <Select
                    value={clientFilter}
                    label="Clients"
                    onChange={(e) => {
                      setClientFilter(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    {clients?.map((value, i) => (
                      <MenuItem value={value.id} key={i}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} pr={3}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={filter}
                    label="Status"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    {surveyStatusList?.map((value, i) => {
                      return (
                        <MenuItem key={i} value={value.id}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <SearchInput setSearch={setSearch} search={search} />
              <MDBox
                mx={2}
                mt={2}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="row"
                gap="10px"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    const dataToConvert = {
                      data: surveys,
                      filename: `SurveyPage${page}`,
                      delimiter: ",",
                    };
                    csvDownload(dataToConvert);
                  }}
                >
                  <img
                    src={Union2}
                    width="15px"
                    height="15px"
                    style={{ paddingRight: "5px" }}
                    alt="download"
                  />
                  Download
                </MDButton>
              </MDBox>
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  navigate("/surveys/new");
                  setSurvey({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + New Survey
              </MDButton>
            </Grid>
          </Grid>

          <DataTable
            table={{ columns, rows }}
            isSorted
            noEndBorder
            entriesPerPage={entriesPerPage}
            canSearch={false}
            tablePage={page}
            setTablePage={setPage}
            navigateRow={"/surveys"}
            setEntriesPerPage={setEntriesPerPage}
            totalLength={totalLength}
            pagination={true}
            setSortOrder={setSortOrder}
            setSortBy={setSortBy}
            rowWidth={"40px"}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setIsLoading={setIsLoading}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default SurveyList;
