import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetBuyerList = (id) => {
  const [buyerList, setBuyerList] = useState([]);
  const [buyerListMap, setBuyerListMap] = useState([]);

  const getBuyer = async () => {
    try {
      let data = await TLinkApi.get(`clients/${id}/buyers`);
      setBuyerList(data.data);
      let buyerMap = [];
      data.data.map((value) => {
        buyerMap[value.buyerId] = value.buyerName;
        return true;
      });
      setBuyerListMap(buyerMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (id) getBuyer();
    // eslint-disable-next-line
  }, [id]);
  return { buyerList, getBuyer, buyerListMap };
};

export default GetBuyerList;
