import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import GetTopSupplierList from "hooks/TopSupplierList/GetTopSupplierList";
import { Tooltip } from "@mui/material";

function TopSupplier({ timeline }) {
  const { topSupplier } = GetTopSupplierList(timeline);
  const [search, setSearch] = useState("");
  const [filterTopSurvey, setFilterTopSurvey] = useState([]);

  useEffect(() => {
    if (search && search !== "") {
      const fTopSurvey = topSupplier
        .filter((survey) => {
          if (search === "") return true;
          else {
            const s = search.toLowerCase();
            let match = false;
            if (survey.supplierId && survey.supplierId.toString().includes(s)) {
              match = true;
            }
            if (survey.supplierName && survey.supplierName.toLowerCase().includes(s)) {
              match = true;
            }

            return match;
          }
        })
        .map((c) => c);
      setFilterTopSurvey(fTopSurvey);
    } else {
      setFilterTopSurvey(topSupplier);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    setFilterTopSurvey(topSupplier);
    // eslint-disable-next-line
  }, [topSupplier]);

  let rows = filterTopSurvey && filterTopSurvey;

  let columns = [
    {
      accessor: "supplierId",
      Header: "Supplier Id",
      align: "center",
    },
    {
      accessor: "supplierName",
      Header: "Supplier Name",
      align: "center",
      Cell: ({ row }) => {
        const name = row.original.supplierName || "";
        const truncatedName = name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "starts",
      Header: "Starts",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "completes",
      Header: "Completes",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "conversion",
      Header: "Conversion",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.conversion).toFixed(2);
      },
    },
    {
      accessor: "avgCpi",
      Header: "Cpi",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.avgCpi).toFixed(2);
      },
    },

    {
      accessor: "epc",
      Header: "EPC",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.epc).toFixed(2);
      },
    },
    {
      accessor: "revenue",
      Header: "Cost",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.revenue).toFixed(2);
      },
    },
    {
      accessor: "profit",
      Header: "Profit",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) => {
        return parseFloat(params.cell.row.original.profit).toFixed(2);
      },
    },
  ];

  return (
    <MDBox>
      <Grid container spacing={2} pr={3} pt={2} pl={3}>
        <Grid item xs={12} md={3} style={{ color: "#6E9633" }}></Grid>
        <Grid
          item
          xs={12}
          md={9}
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-end", md: "center" },
          }}
          gap="15px"
        >
          <SearchInput search={search} setSearch={setSearch} />
        </Grid>
      </Grid>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted
        noEndBorder
        tableMaxHeight="700px"
        entriesPerPage={false}
        canSearch={false}
      />
    </MDBox>
  );
}

export default TopSupplier;
