import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "assets/theme/base/colors";

const StatusTable = ({ reconcileData }) => {
  let { textColor } = colors;
  return (
    <TableContainer
      sx={{
        height: 350,
        boxShadow: "none",
        border: "1px solid #BFBFBF",
        marginTop: "10px",
      }}
    >
      <Table
        sx={{
          overflow: "auto",
          "& th": {
            color: textColor.main,
            fontSize: "14px",
          },
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: "400",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell>Token Id</TableCell>
            <TableCell align="center">Client Token</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reconcileData.map((value, i) => (
            <TableRow key={i}>
              <TableCell>{value?.respondentToken ? value.respondentToken : "NA"}</TableCell>
              <TableCell align="center">{value?.clientToken}</TableCell>
              <TableCell align="center" style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="rowStatus"
                  style={{
                    borderColor: !value?.matched ? "#FFA39E" : "",
                    color: !value?.matched ? "red" : "",
                    background: !value?.matched ? "#FFF1F0" : "",
                  }}
                >
                  {value?.matched ? "Matched" : "Unmatched"}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatusTable;
