import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetProjectManagerList = () => {
  const [projectManager, setProjectManager] = useState([]);
  const [search, setSearch] = useState("");

  const getProjectManager = async () => {
    try {
      let data = await TLinkApi.get(`/users/managers?search=${search}`);
      setProjectManager(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjectManager();
    // eslint-disable-next-line
  }, [search]);
  return {
    projectManager,
    getProjectManager,
    setSearch,
    search,
  };
};

export default GetProjectManagerList;
