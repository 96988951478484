import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetIndustryList = () => {
  const [industry, setIndustry] = useState([]);
  const [industryMap, setIndustryMap] = useState([]);
  const getIndustry = async () => {
    try {
      let data = await TLinkApi.get("/masters/industry");
      setIndustry(data.data);
      let industryMap = [];
      data.data.map((value) => {
        industryMap[value.id] = value.name;
        return true;
      });
      setIndustryMap(industryMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getIndustry();
    // eslint-disable-next-line
  }, []);
  return { industry, getIndustry, industryMap };
};

export default GetIndustryList;
