import { Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const QuotaQuestionBox = ({ question, updateQuestion, index, quotaQuestions, setQuotaError }) => {
  useEffect(() => {
    if (quotaQuestions[index].answerId.length <= 0) {
      setQuotaError(true);
    } else {
      setQuotaError(false);
    }
    // eslint-disable-next-line
  }, [quotaQuestions]);
  return (
    <MDBox p={3} fontSize="15px" bgColor="#E6F7FD" mt={1}>
      <Grid container spacing={1} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={10} pb={1}>
          <MDBox display="flex" alignItems="flex-start" gap="20px">
            <MDBox ml={-2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
              >
                &nbsp;&nbsp;Q:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox ml={-1} flexWrap="wrap">
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
              >
                &nbsp;&nbsp;{question.question.questionText}&nbsp;
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={10}>
          <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap">
            <FormGroup>
              {question?.question?.answers
                .filter((option, i) => {
                  if (question?.selectedAnswers?.includes(parseInt(option.id))) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((option, i) => (
                  <MDBox display="flex" alignItems="center" ml={3} key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={question.answerId.includes(parseInt(option.id))}
                          onChange={(e, value) => {
                            let data;
                            if (!e.target.checked) {
                              data = [...quotaQuestions];
                              data[index].answerId = data[index].answerId.filter(
                                (answer) => answer !== parseInt(option.id)
                              );
                            } else {
                              data = [...quotaQuestions];
                              data[index].answerId.push(parseInt(option.id));
                            }
                            updateQuestion("quotaQuestions", data);
                          }}
                        />
                      }
                      label={
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="dark"
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{option?.qualificationAnswerDesc}&nbsp;
                        </MDTypography>
                      }
                    />
                  </MDBox>
                ))}
              {quotaQuestions[index].answerId.length <= 0 ? (
                <MDBox color="error">Please select atleast one field</MDBox>
              ) : (
                ""
              )}
            </FormGroup>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default QuotaQuestionBox;
