import { Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { useEffect } from "react";

function QuotaQualificationChart(props) {
  const [qualificationObj, setQualificationObj] = useState({});
  useEffect(() => {
    props.quota
      .filter((value, i) => {
        if (value.qualificationId === props.val.qualificationId) {
          return true;
        } else {
          return false;
        }
      })
      .map((val) => {
        let payload = {};
        payload.qualificationTypeId = val.qualificationTypeId;
        payload.questionText = val.question.questionText;
        payload.answers = val.question.answers;
        setQualificationObj(payload);
        return true;
      });
  }, [props]);

  return (
    <MDBox p={1} my={1} fontSize="15px" bgColor="#E6F7FD" pb={2}>
      <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
        <Grid item xs={10} md={10}>
          {qualificationObj.qualificationTypeId === 1 ||
          qualificationObj.qualificationTypeId === 2 ? (
            <Grid item xs={12} md={12}>
              <MDBox
                display="flex"
                alignItems="center"
                flexDirection="row"
                gap="20px"
                flexWrap="wrap"
                mt={1}
              >
                <MDBox display="flex" alignItems="center" ml={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
                  >
                    Q:{props.index + 1} &nbsp;&nbsp;{qualificationObj.questionText}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container spacing={1} pt={1} style={{ paddingLeft: "5px" }}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={1}>
                    {qualificationObj.answers.map((option, i) => (
                      <Grid item xs={12} md={12} lg={5} ml={3} key={i}>
                        <Checkbox
                          disabled={props.isEdit.disabled}
                          onChange={(e, value) => {
                            let payload = [];
                            if (!e.target.checked) {
                              payload = [...props.data];
                              payload[props.dataIndex].quota.conditions[props.index].answerId =
                                payload[props.dataIndex].quota.conditions[
                                  props.index
                                ].answerId.filter((answer) => answer !== parseInt(option.id));
                            } else {
                              payload = [...props.data];
                              payload[props.dataIndex].quota.conditions[props.index].answerId.push(
                                parseInt(option.id)
                              );
                            }
                            props.setData(payload);
                          }}
                          checked={props.val.answerId.includes(parseInt(option.id))}
                        />
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="dark"
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;{option.qualificationAnswerDesc}&nbsp;
                        </MDTypography>
                      </Grid>
                    ))}
                  </Grid>
                  {props.data[props.dataIndex].quota?.conditions[props.index].answerId.length <=
                  0 ? (
                    <MDBox color="error" px={2}>
                      Please select atleast one field
                    </MDBox>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : qualificationObj.qualificationTypeId === 4 ? (
            <>
              <Grid container spacing={1} style={{ paddingLeft: "2px" }}>
                <Grid item xs={10} md={10} pb={2}>
                  <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={1}>
                    <MDBox display="flex" alignItems="center" ml={-2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
                      >
                        &nbsp;&nbsp;Q:{props.index + 1}&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
                      >
                        &nbsp;&nbsp;{qualificationObj.questionText}&nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              {props?.val?.selectedRange?.map((value, i) => (
                <Grid container spacing={2} style={{ paddingLeft: "30px" }} key={i} mb={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          autoComplete="off"
                          variant="outlined"
                          label="min"
                          fullWidth
                          required
                          disabled={props.isEdit.disabled}
                          value={value.min}
                          onChange={(e) => {
                            let payload = [...props.data];
                            payload[props.dataIndex].quota.conditions[props.index].selectedRange[
                              i
                            ].min = e.target.value;
                            props.setData(payload);
                          }}
                          error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
                          helperText={
                            value.min === ""
                              ? "This field is Required"
                              : Number(value.min) < 10
                              ? "This value can not be less then 10"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          fullWidth
                          required
                          disabled={props.isEdit.disabled}
                          value={value.max}
                          onChange={(e) => {
                            let payload = [...props.data];
                            payload[props.dataIndex].quota.conditions[props.index].selectedRange[
                              i
                            ].max = e.target.value;
                            props.setData(payload);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          label="max"
                          error={
                            value.max === ""
                              ? true
                              : Number(value.min) > Number(value.max)
                              ? true
                              : Number(value.max) > 100
                              ? true
                              : false
                          }
                          helperText={
                            value.max === ""
                              ? "This field is Required"
                              : Number(value.max) > 100
                              ? "This value can not be greater than 100"
                              : Number(value.min) > Number(value.max)
                              ? "This is not be less then min"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid container spacing={1} style={{ paddingLeft: "2px" }}>
                <Grid item xs={10} md={10} pb={2}>
                  <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={1}>
                    <MDBox display="flex" alignItems="center" ml={-2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
                      >
                        &nbsp;&nbsp;Q:{props.index + 1}&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
                      >
                        &nbsp;&nbsp;{qualificationObj.questionText}&nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingLeft: "30px" }}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <MDInput
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        label="Answer Text"
                        fullWidth
                        required
                        disabled={props.isEdit.disabled}
                        value={props?.val?.text}
                        onChange={(e) => {
                          let payload = [...props.data];
                          payload[props.dataIndex].quota.conditions[props.index].text =
                            e.target.value;
                          props.setData(payload);
                        }}
                        error={
                          props.data[props.dataIndex]?.quota?.conditions[props.index].text === ""
                            ? true
                            : false
                        }
                        helperText={
                          props.data[props.dataIndex]?.quota?.conditions[props.index].text === ""
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuotaQualificationChart;
