import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

function GetSurveyTypeList() {
  const [surveyType, setSurveyType] = useState([]);
  const getSurveyType = async () => {
    try {
      let data = await TLinkApi.get("/masters/surveytype");
      setSurveyType(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getSurveyType();
    // eslint-disable-next-line
  }, []);
  return { surveyType, getSurveyType };
}

export default GetSurveyTypeList;
