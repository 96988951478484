import Grid from "@mui/material/Grid";
import { Autocomplete, Backdrop, CircularProgress, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import QuotaQuestionBox from "comps/Cards/quotaCheckbox";
import MinMax from "comps/Cards/quotaMInMax";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuotaList from "./QuotaList";
import QuotaAnswerText from "comps/Cards/quotaAnsText";

function QuotaSurvey() {
  let { enqueueSnackbar } = useSnackbar();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      range: "",
      quotaQuestions: [],
      isActive: true,
      quotaCpi:"",
    },
  });

  let { setSurvey, setIsEdit, isEdit } = useOutletContext();
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showDown, setShowDown] = useState(false);
  const [quota, setQuota] = useState([]);
  const [quotaIndex, setQuotaIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(false);
  const [quotaError, setQuotaError] = useState(false);

  let { id } = useParams();

  let quotaQuestions = watch("quotaQuestions");
  // let isActive = watch("isActive");

  let getQuotaQualificationList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/quota`);
      if (result.length > 0) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      let payload = result.map((qu) => {
        qu.quota.conditions.map((value) => {
          quota.filter((val) => {
            if (value.qualificationId === val.qualificationId) {
              if (val.qualificationTypeId === 4) {
                let ranges = value.answerText.map((value) => {
                  let rangeValue = value.split("-");
                  let payload = {
                    min: parseInt(rangeValue[0]),
                    max: parseInt(rangeValue[1]),
                  };
                  return payload;
                });
                value.range = ranges;
                value.selectedRange = ranges;
                value.question = val.question;
                value.selectedAnswers = [];
                value.qualificationTypeId = val.qualificationTypeId;
              } else if (val.qualificationTypeId === 3) {
                let tempSplitValue = value?.answerText[0];
                value.range = tempSplitValue;
                value.text = tempSplitValue;
                value.question = val.question;
                value.selectedAnswers = [];
                value.qualificationTypeId = val.qualificationTypeId;
              } else {
                value.question = val.question;
                value.selectedAnswers = val.answerId;
                value.qualificationTypeId = val.qualificationTypeId;
              }
            }
          });
        });
        return qu;
      });
      setData(payload);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (quota.length > 0) {
      getQuotaQualificationList();
    }
    // eslint-disable-next-line
  }, [quota]);

  useEffect(() => {
    if (id) {
      getQuotaList();
      getQuotaQualificationList();
    }
    // eslint-disable-next-line
  }, [id]);

  let getQuotaList = async () => {
    try {
      let result = await TLinkApi.get(`survey/${id}/qualification/conditions`);

      let tempQualificationQuestions = result?.qualifications.map((qualification) => {
        if (qualification.qualificationTypeId === 4) {
          let ranges = qualification.answerText.map((value) => {
            let rangeValue = value.split("-");
            let payload = {
              min: parseInt(rangeValue[0]),
              max: parseInt(rangeValue[1]),
            };
            return payload;
          });
          qualification.range = ranges;
          qualification.selectedRange = ranges;
        } else if (qualification.qualificationTypeId === 3) {
          let tempSplitValue = qualification?.answerText[0];
          qualification.range = tempSplitValue;
          qualification.text = tempSplitValue;
        } else {
          qualification.selectedAnswers = qualification.answerId;
        }
        return qualification;
      });
      setQuota(tempQualificationQuestions);
    } catch (e) {
      console.log(e);
    }
  };

  const save = async (payload) => {
    if (!quotaError) {
      let conditions = quotaQuestions.map((val) => {
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          return {
            qualificationId: val.qualificationId,
            answerId: val.answerId,
          };
        } else if (val.qualificationTypeId === 4) {
          let range = val.selectedRange.map((value) => {
            return `${value.min}-${value.max}`;
          });
          return {
            qualificationId: val.qualificationId,
            answerText: range,
          };
        } else {
          return {
            qualificationId: val.qualificationId,
            answerText: [val.text],
          };
        }
      });
      let surveyQuota = {
        surveyId: id,
        quota: {
          name: payload.name,
          sampleNumber: payload.range,
          conditions: conditions,
          isActive: isActive,
        },
      };

      try {
        await TLinkApi.post("/survey/quota", surveyQuota);
        enqueueSnackbar("created", {
          variant: "success",
        });
        getQuotaList();
        setValue("quotaQuestions", []);
        setShowDown(!showDown);
        setValue("name", "");
        setValue("range", "");
        getQuotaQualificationList();
        setQuotaIndex("");
      } catch (e) {
        enqueueSnackbar("Oopss! somthig went wrong", {
          variant: "error",
        });
      }
    }
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        sx={{ height: `700px`, overflowY: "auto", borderRadius: "16px" }}
        bgColor="white"
        m={2}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} mx={2}>
            {data?.map((value, index) => {
              return (
                <QuotaList
                  key={index}
                  index={index}
                  quotaIndex={quotaIndex}
                  setQuotaIndex={setQuotaIndex}
                  data={data}
                  value={value}
                  setData={setData}
                  quota={quota}
                  getQuotaList={getQuotaList}
                  getQuotaQualificationList={getQuotaQualificationList}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                />
              );
            })}
          </Grid>
          <Grid item xs={12} md={12} mx={2}>
            <form onSubmit={handleSubmit(save)} noValidate>
              <MDBox border="1px solid #D9D9D9" borderRadius="10px" bgColor="white" mt={2}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                  height={"80px"}
                  gap="10px"
                  mb={1}
                >
                  <MDBox
                    width="5%"
                    sx={{ width: { md: "10%", lg: "5%" } }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={2.5}
                  >
                    <IconButton
                      sx={{ transform: height === true ? "rotate(180deg)" : "" }}
                      onClick={() => {
                        if (height) {
                          setHeight(false);
                        } else {
                          setHeight(true);
                        }
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </MDBox>
                  <MDBox width="70%" sx={{ width: { xs: "40%", md: "50%", lg: "70%" } }}>
                    <MDBox
                      color="textColor"
                      style={{ marginBottom: "2px" }}
                      sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                    >
                      Quota Name
                    </MDBox>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: "Name is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          autoComplete="off"
                          placeholder="Quota Name"
                          variant="outlined"
                          type="text"
                          error={errors?.name ? true : false}
                          helperText={errors?.name ? errors.name.message : null}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox width="10%">
                    <MDBox
                      color="textColor"
                      style={{ marginBottom: "2px" }}
                      sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                    >
                      No. Of Participants
                    </MDBox>
                    <Controller
                      name="range"
                      control={control}
                      rules={{
                        required: "Range is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          placeholder="No. Of Participants"
                          required
                          autoComplete="off"
                          variant="outlined"
                          type="number"
                          error={errors?.range ? true : false}
                          helperText={errors?.range ? errors.range.message : null}
                        />
                      )}
                    />
                  </MDBox>

                  <MDBox width="10%">
                    <MDBox
                      color="textColor"
                      style={{ marginBottom: "2px" }}
                      sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                    >
                      CPI
                    </MDBox>
                    <Controller
                      name="quotaCpi"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          disabled
                          fullWidth
                          placeholder="CPI"
                          required
                          autoComplete="off"
                          variant="outlined"
                          type="number"
                          // error={errors?.quotaCpi ? true : false}
                          // helperText={errors?.quotaCpi ? errors.quotaCpi.message : null}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox
                    width="10%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: { md: "20%", lg: "10%" } }}
                    mt={2.5}
                  >
                    <Switch
                      checked={isActive}
                      onChange={(event) => {
                        setIsActive(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    {/* <Switch
                      value={isActive}
                      onChange={(e, value) => {
                        console.log(value);
                        if (value) {
                          setValue("isActive", value);
                        }
                      }}
                    /> */}
                    {/* <IconButton>
                      <IndeterminateCheckBoxOutlinedIcon />
                    </IconButton> */}
                  </MDBox>
                </MDBox>

                <MDBox
                  width="84.5%"
                  sx={{ marginLeft: { md: "10%", lg: "5.4%" } }}
                  display={height === true ? "" : "none"}
                  mb={1}
                >
                  <Controller
                    name="quotaQuestions"
                    control={control}
                    rules={{
                      required: "Quota Questions is required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple
                        limitTags={1}
                        value={value}
                        onChange={(e, value) => {
                          onChange(JSON.parse(JSON.stringify(value)));
                        }}
                        options={quota}
                        getOptionLabel={(option) => option?.question?.questionText}
                        isOptionEqualToValue={(option, value) =>
                          option?.question?.questionText === value?.question?.questionText
                        }
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "20px",
                          },
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            placeholder="Question List"
                            size="small"
                            error={errors?.quotaQuestions ? true : false}
                            helperText={
                              errors?.quotaQuestions ? errors.quotaQuestions.message : null
                            }
                          />
                        )}
                      />
                    )}
                  />
                  {quotaQuestions.map((value, i) =>
                    value.qualificationTypeId === 4 ? (
                      <Grid item xs={12} md={12} key={i}>
                        <MinMax
                          question={value}
                          updateQuestion={setValue}
                          i={i}
                          quotaQuestions={quotaQuestions}
                          setQuotaError={setQuotaError}
                        />
                      </Grid>
                    ) : value.qualificationTypeId === 3 ? (
                      <QuotaAnswerText
                        question={value}
                        updateQuestion={setValue}
                        i={i}
                        quotaQuestions={quotaQuestions}
                        setQuotaError={setQuotaError}
                      />
                    ) : (
                      <Grid item xs={12} md={12} key={i}>
                        <QuotaQuestionBox
                          question={value}
                          updateQuestion={setValue}
                          index={i}
                          quotaQuestions={quotaQuestions}
                          setQuotaError={setQuotaError}
                        />
                      </Grid>
                    )
                  )}
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexDirection="row"
                    mt={1}
                  >
                    <MDButton variant="outlined" color="info" type="submit" size="small">
                      add
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt={2}
        mx={2}
        gap="10px"
        pb={2}
      >
        <MDButton
          variant="outlined"
          color="dark"
          onClick={() => {
            navigate(`/surveys`);
            setValue("quotaQuestions", []);
            setShowDown(!showDown);
            setValue("name", "");
            setValue("range", "");
            setQuotaIndex("");
            setSurvey({});
            setIsEdit({
              disabled: false,
              edit: false,
            });
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            setSurvey({});
            navigate(`/surveys/${id}/supplier/edit`);
          }}
        >
          next
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default QuotaSurvey;
