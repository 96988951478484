import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import GetSurveyReport from "hooks/SurveyReport/GetSurveyReport";
import DataTable from "comps/Tables/DataTable";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import MDBox from "components/MDBox";
import SearchInput from "components/searchInput";
import Union2 from "assets/images/Union2.png";
import csvDownload from "json-to-csv-export";

function SurveyReport() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { surveyReport, isLoading } = GetSurveyReport({ id });
  const [page, setPage] = useState(1);
  const [surveyReports, setSurveyReports] = useState([]);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);

  let rows = surveyReports.slice(
    page * entriesPerPage - entriesPerPage,
    page * entriesPerPage
  );

  useEffect(() => {
    setSurveyReports(surveyReport);
    // eslint-disable-next-line
  }, [surveyReport]);

  useEffect(() => {
    if (search && search !== "") {
      const fSurveyReports = surveyReports
        .filter((value) => {
          if (search === "") return true;
          else {
            const s = search.toLowerCase();
            let match = false;
            if (value.status && value.status.toLowerCase().includes(s)) {
              match = true;
            }
            if (
              value.vendorName &&
              value.vendorName.toLowerCase().includes(s)
            ) {
              match = true;
            }
            if (value.token && value.token.toLowerCase().includes(s)) {
              match = true;
            }
            if (value.subStatus && value.subStatus.toLowerCase().includes(s)) {
              match = true;
            }
            return match;
          }
        })
        .map((c) => c);
      setSurveyReports(fSurveyReports);
    } else {
      setSurveyReports(surveyReport);
    }
    // eslint-disable-next-line
  }, [search]);

  let columns = [
    {
      accessor: "surveyId",
      Header: "Survey Id",
      align: "left",
    },
    {
      accessor: "status",
      Header: "Status",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },

    {
      accessor: "subStatus",
      Header: "Sub Status",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },

    {
      accessor: "vendorName",
      Header: "Supplier Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "supplierToken",
      Header: "Supplier Token",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "token",
      Header: "Token",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row.original.token;
        const truncatedName =
          name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "startDate",
      Header: "Start Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) =>
        `${moment(params.row.original.startDate).format("DD-MM-YYYY")}`,
    },
    {
      accessor: "endDate",
      Header: "End Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: (params) =>
        `${moment(params.row.original.endDate).format("DD-MM-YYYY")}`,
    },
  ];

  return (
    <MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
          <Grid container spacing={2} pb={3}>
            <Grid
              px={2}
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
            >
              <SearchInput setSearch={setSearch} search={search} />
            </Grid>
          </Grid>
          <DataTable
            table={{ columns, rows }}
            isSorted
            noEndBorder
            entriesPerPage={entriesPerPage}
            canSearch={false}
            tablePage={page}
            setTablePage={setPage}
            tableMaxHeight={"calc(100vh - 160px)"}
            setEntriesPerPage={setEntriesPerPage}
            totalLength={Math.ceil(surveyReports.length)}
            pagination={true}
          />
        </MDBox>
      </MDBox>
      <MDBox
        mx={2}
        mt={2}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="row"
        gap="10px"
      >
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            const dataToConvert = {
              data: surveyReport,
              filename: "Survey Report",
              delimiter: ",",
            };
            csvDownload(dataToConvert);
          }}
        >
          <img
            src={Union2}
            width="15px"
            height="15px"
            style={{ paddingRight: "5px" }}
            alt="export"
          />
          Export
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate("/surveys");
          }}
        >
          Back
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default SurveyReport;
