import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import TLinkApi from "services/tlink.api";
function UserResetPassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [resetSuccess, setResetSuccess] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      forgetPasswordToken: "",
    },
  });
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const handleFormSubmit = async (requestData) => {
    try {
      let payload = { ...requestData };
      payload.forgetPasswordToken = token;
      
      const { data: responseData, status } = await Axios.patch(
        TLinkApi.baseUrl + "/forgetPasswordUpdate",
        payload
      );
      console.log(responseData);
      if (status === 200) {
        enqueueSnackbar(responseData?.message, {
          variant: "success",
        });
        navigate("/login");
      }
    
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
      // alert("Login failed. Please check your credentials!");
    }
    // console.log(payload);
    // enqueueSnackbar("Password has been reset successfully.", {
    //   variant: "success",
    // });
  };
  

  const confirmPassword = watch("confirmPassword");

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Confirm password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            <MDBox mb={2}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Password"
                    //variant="standard"
                    fullWidth
                    error={errors?.password ? true : false}
                    helperText={errors?.password ? errors.password.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm Password is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Confirm Password"
                    {...register("confirmPassword", {
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                    fullWidth
                    required
                    error={errors?.confirmPassword ? true : false}
                    helperText={errors?.confirmPassword ? errors.confirmPassword.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Reset Password
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default UserResetPassword;
