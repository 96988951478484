import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReconcileStatusList = () => {
  const [reconcileStatus, setReconcileStatus] = useState([]);
  const getReconcileStatus = async () => {
    try {
      let data = await TLinkApi.get("masters/reconciliations");
      setReconcileStatus(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getReconcileStatus();
    // eslint-disable-next-line
  }, []);
  return { reconcileStatus, getReconcileStatus };
};

export default GetReconcileStatusList;
