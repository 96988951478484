import MDBox from "components/MDBox";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { IconButton, Tab, Tabs } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";

function AddSurvey() {
  const [quotaQualificationQuestions, setQuotaQualificationQuestions] = useState([]);
  const [langId, setLangId] = useState("");
  const location = useLocation();
  let navigate = useNavigate();
  let { textColor } = colors;

  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  const { survey, setSurvey, setIsEdit, isEdit } = useOutletContext();

  let { id } = useParams();

  useEffect(() => {
    if (window.location.pathname === `/surveys/${id}/qualification/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/surveys/${id}/quota/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/surveys/${id}/supplier/edit`) {
      setValue(3);
    } else if (window.location.pathname === `/surveys/${id}/securityConfig/edit`) {
      setValue(4);
    } else if (window.location.pathname === `/surveys/${id}/report/edit`) {
      setValue(5);
    } else if (window.location.pathname === `/surveys/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/surveys/new`) {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            setSurvey({});
            navigate(-1);
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? id : "New Survey"}
      </MDTypography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab
          label="Basic Details"
          index={0}
          component={Link}
          to={id ? `/surveys/${id}/edit` : `/surveys/new`}
        />
        <Tab
          label="qualification"
          index={1}
          component={Link}
          disabled={!id}
          to={`/surveys/${id}/qualification/edit`}
        />
        <Tab
          label="quota"
          index={2}
          component={Link}
          to={`/surveys/${id}/quota/edit`}
          disabled={!id}
        />
        <Tab
          label="supplier"
          index={3}
          component={Link}
          to={`/surveys/${id}/supplier/edit`}
          disabled={!id}
        />
        <Tab
          label="Security Config"
          index={4}
          component={Link}
          to={`/surveys/${id}/securityConfig/edit`}
          disabled={!id}
        />
        <Tab
          label="Survey Reports"
          index={5}
          component={Link}
          to={`/surveys/${id}/report/edit`}
          disabled={!id}
        />
      </Tabs>
      <Outlet
        context={{
          quotaQualificationQuestions,
          setQuotaQualificationQuestions,
          survey,
          setSurvey,
          setIsEdit,
          isEdit,
          setLangId,
          langId,
        }}
      />
    </MDBox>
  );
}

export default AddSurvey;
