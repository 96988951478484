import Icon from "@mui/material/Icon";
import client from "../src/assets/sidebarLogo/clients.png";
import projects from "../src/assets/sidebarLogo/projects.png";
import qualification from "../src/assets/sidebarLogo/qualification.png";
import survey from "../src/assets/sidebarLogo/survey.png";
import vendor from "../src/assets/sidebarLogo/vendor.png";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
  },

  {
    type: "title",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="small">assessment_icon</Icon>,
    collapse: [
      {
        name: "Surveys",
        key: "surveys",
        route: "/surveys",
        icon: survey,
      },
      {
        name: "Projects",
        key: "projects",
        route: "/projects",
        icon: projects,
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">descriptionoutlinedicon</Icon>,
    route: "/reports",
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">campaignicon</Icon>,
    route: "/campaigns",
  },
  {
    type: "title",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="small">personoutlineOutlinedicon</Icon>,
    collapse: [
      {
        name: "Vendors",
        key: "vendors",
        route: "/vendors",
        icon: vendor,
      },
      {
        name: "Clients",
        key: "clients",
        route: "/clients",
        icon: client,
      },
      {
        name: "Qualification",
        key: "qualification",
        route: "/qualification",
        icon: qualification,
      },
      // {
      //   name: "User Mangment",
      //   key: "userMangment",
      //   icon: <Icon fontSize="small">api</Icon>,
      //   route: "/userMangment",
      //   component: <ApiSurvey />,
      // },
    ],
  },

  // {
  //   type: "title",
  //   name: "Setting",
  //   key: "setting",
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       icon: <Icon fontSize="small">person</Icon>,
  //       route: "/profile",
  //       component: <Profile />,
  //     },
  //   ],
  // },
];

export default routes;
